import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import { submitToFeedBack } from "./api/FeedbackApi";
import { TDI_CONST } from "../utils/constants/tdi";
import { toasterError, toasterSuccess } from "../utils/helper/commonHelper";

const FeedbackSlice = createSlice({
    name: STORE_CONST.FEED_BACK_NAMESPACE,
    initialState: {
        isLoad: false,
        feedBackData: {
            data: {},
            status: "",
        },
    },
    reducers: {
        setIsLoad(state, action) {
            state.isLoad = action.payload.isLoad;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submitToFeedBack.rejected, (state, { payload }) => {
            state.isLoad = false;
        });
        builder.addCase(submitToFeedBack.pending, (state, { payload }) => {
            state.isLoad = true;
        });
        builder.addCase(submitToFeedBack.fulfilled, (state, { payload }) => {
            state.isLoad = false;
            if (!payload?.hasError && payload?.errorCode === -1) {
                state.feedBackData.data = payload?.response;
                state.feedBackData.status = TDI_CONST.API_SUCCESS;
                toasterSuccess(TDI_CONST.FEEDBACK_SUCCESS);
            } else {
                state.feedBackData.status = TDI_CONST.API_ERROR;
                toasterError(TDI_CONST.FEEDBACK_ERROR);
            }
        })
    },
});

export const feddBackActions = FeedbackSlice.actions;

export default FeedbackSlice;
