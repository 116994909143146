import './App.css';
import Router from "./Router";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <React.Fragment>
       <ToastContainer />
      <Router />
    </React.Fragment>
  );
}

export default App;
