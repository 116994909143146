import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import { SubmitFeedBackApi } from "./api/FeedBackSendApi";
import { TDI_CONST } from "../utils/constants/tdi";

const FeedBackSendSlice = createSlice({
  name: STORE_CONST.FEED_BACK_NAMESPACE,
  initialState: {
    isLoad: false,
    isFeedbackModalOpen: false,
    feedBackData: {
      data: {},
      status: "",
    },
  },

  reducers : {
    setIsLoads(state,action)
    {
        state.isLoad = action.payload.isLoad;
 
        state.isFeedbackModalOpen = action.payload
    },
  },
  extraReducers :(builder) =>
  {
    builder.addCase(SubmitFeedBackApi.rejected,(state,{payload})=>{

        state.isLoad = false;
    });
    builder.addCase(SubmitFeedBackApi.pending,(state,{payload})=>
    {
        state.isLoad = false;
       
    });
    builder.addCase(SubmitFeedBackApi.fulfilled,(state,{payload})=>
    {
        state.isLoad = true;
        state.data = payload
        state.status=TDI_CONST.API_SUCCESS
        console.log(payload)
      
    
    })
  }
});

export const { setIsLoads } = FeedBackSendSlice.actions;

export default FeedBackSendSlice;
