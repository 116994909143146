import { Routes, Route, Navigate } from "react-router-dom";
import React, { Suspense } from "react";

const SearchPage = React.lazy(() => import("./pages/search/SearchPage"));
const NotFound = React.lazy(() => import("./pages/not-found/NotFound"));
const HomePage = React.lazy(() => import("./components/search/homePage"));

function Router() {
  return (
    <React.Fragment>
      <Suspense fallback={<></>}>
        <Routes>
          {/* <Route path="/" element={<Navigate replace to="/chatgpt" />} /> */}
          <Route path="/testing" element={<SearchPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </React.Fragment>
  );
}

export default Router;
