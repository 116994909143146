import { toast } from "react-toastify";

export const toasterSuccess = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT,
    theme: "colored",
    draggable: true,
  });
};

export const toasterError = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_RIGHT,
    theme: "colored",
    draggable: true,
  });
};

export function generateKey() {
  const alphabetPart = getRandomLetters(4); // Generates a random 4-letter alphabet
  const numericPart = getRandomNumbers(3);  // Generates a random 3-digit number

  return `${alphabetPart}${numericPart}`;
}

function getRandomLetters(length) {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += letters.charAt(Math.floor(Math.random() * letters.length));
  }
  return result;
}

function getRandomNumbers(length) {
  const numbers = '0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += numbers.charAt(Math.floor(Math.random() * numbers.length));
  }
  return result;
}

// export function renderClickableLinks(message) {
//   const urlRegex = /(https?:\/\/[^\s]+)/g;
//   const links = message.match(urlRegex);

//   return (
//     <div className="chat-message">
//       {links
//         ? <>
//             {message.split(urlRegex).map((part, index) => (
//               index % 2 === 0
//                 ? part  // Non-link text
//                 : <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>
//             ))}
//           </>
//         : message
//       }
//     </div>
//   );
// }

export function renderClickableLinks(message) {
  const urlRegex = /(https?:\/\/[^\s\])]+)/g;
  const links = message.match(urlRegex);
  const messages = message.split('\n'); // Split the message by newline characters

  return (
    <div className="chat-message">
      {messages.map((msg, msgIndex) => (
        <p key={msgIndex}>
          {msg.split(urlRegex).map((part, index) => (
            index % 2 === 0
              ? part // Non-link text
              : <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>
          ))}
        </p>
      ))}
    </div>
  );
}
