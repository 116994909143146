import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import { ExistingKeys } from "./api/ExistingKeysApi";

const ExistingKeySlice = createSlice({
  name: STORE_CONST.EXISTING_KEYS,
  initialState: {
    isLoad: false,
    ExistingKey: {
      data: {},
      status: "",
    },
  },

  reducers : {
    setIsLoad(state,action)
    {
        state.isLoad = action.payload.isLoad;
    },
  },
  extraReducers :(builder) =>
  {
    builder.addCase(ExistingKeys.rejected,(state,{payload})=>{
        state.isLoad = false;
    });
    builder.addCase(ExistingKeys.pending,(state,{payload})=>
    {
        state.isLoad = true;
    });
    builder.addCase(ExistingKeys.fulfilled,(state,{payload})=>
    {
        state.isLoad = true;
        state.data = payload
    })
  }
});

export const existingkeyActions = ExistingKeySlice.actions;

export default ExistingKeySlice;
