import { configureStore } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import SearchSlice from "./SearchSlice";
import FeedbackSlice from "./FeedbackSlice";
// import DataSyncSlice from "./DataSyncSlice";
import ExistingKeySlice from "./ExistingKeySlice";
import QuestionnaireSlice from "./QuestionnaireSlice.jsx"
import FeedBackSendSlice from "./FeedBackSendSlice.jsx";
const store = configureStore({
  reducer: {
    [STORE_CONST.SEARCH_NAME_SPACE]: SearchSlice.reducer,
    [STORE_CONST.FEED_BACK_NAMESPACE]: FeedbackSlice.reducer,
    // [STORE_CONST.DATA_SYNC]  : DataSyncSlice.reducer,
    [STORE_CONST.EXISTING_KEYS] : ExistingKeySlice.reducer,
     [STORE_CONST.QUESTIONNAIRE] : QuestionnaireSlice.reducer,
     [STORE_CONST.FEED_BACK_NAMESPACE] : FeedBackSendSlice.reducer

  },
});

export default store;
