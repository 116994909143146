import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/constants/apiUrl";
import { STORE_CONST } from "../../utils/constants/store";
import customAxios from "../../utils/lib/customAxios";

const SubmitFeedBackApi = createAsyncThunk(
  `${STORE_CONST.FEED_BACK_NAMESPACE}/FeedBackSendApi`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.FEED_BACK_API, arg);
      return data;
    } catch (error) {
      console.log("Error", error);
      return rejectWithValue(error.response.data);
    }
  }
);
export { SubmitFeedBackApi };
