import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import { submitToChatGpt } from "./api/SearchApi";
import { TDI_CONST } from "../utils/constants/tdi";

const SearchSlice = createSlice({
  name: STORE_CONST.SEARCH_NAME_SPACE,
  initialState: {
    isLoad: false,
    searchData: {
      data: {},
      status: "",
    },
  },
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload.isLoad;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitToChatGpt.fulfilled, (state, { payload }) => {
        state.searchData.data = payload?.response;
        state.searchData.status = TDI_CONST.API_SUCCESS;
        state.isLoad = true;
    });
    builder.addCase(submitToChatGpt.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.searchData.status = TDI_CONST.API_ERROR;
    });
    builder.addCase(submitToChatGpt.pending, (state, { payload }) => {
      state.isLoad = false;
    });
  },
});

export const searchActions = SearchSlice.actions;

export default SearchSlice;
