export const STORE_CONST = {
  SEARCH_NAME_SPACE: "search",
  HAS: "success",
  COMMON_NAMESPACE: "common",
  FEED_BACK_NAMESPACE: "feedback",
  DATA_SYNC: "datasync",
  EXISTING_KEYS: "ExistingKeys",
  QUESTIONNAIRE: "Questionnaire",
               
};
