import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/constants/apiUrl";
import { STORE_CONST } from "../../utils/constants/store";
import customAxios from "../../utils/lib/customAxios";

const ExistingKeys = createAsyncThunk(
  `${STORE_CONST.EXISTING_KEYS}/ExistingKeys`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.get(API_URL.EXISTING_KEYS_API, arg);
      return data;
    } catch (error) {
      console.log("Error", error);
      return rejectWithValue(error.response.data);
    }
  }
);
export { ExistingKeys };
