import axios from "axios";

export const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {},
});

customAxios.interceptors.request.use((request) => {
  return request;
});

customAxios.interceptors.response.use(
  (response) => {
    if (response?.data?.hasError) {}
    return response;
  },
  (error) => {}
);

export default customAxios;
